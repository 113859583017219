// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// BTN FILTRES
jQuery('#btnMobileFiltre').click(function(e) {
  e.preventDefault();
  jQuery('.aside-product').toggleClass('open');
  jQuery('#btnMobileFiltre').toggleClass('cross');
  jQuery('body').toggleClass('overflow');
});

// CAT NAV
$(".cat-action").click(function(e) {
    e.preventDefault();
    $(".cat-action").toggleClass("active");
    $(this).next(".sub-menu").toggleClass("visible");
});

// USER NAV
$(".nav-user .account-logged .mon-compte").click(function(e) {
    e.preventDefault();
    $(".nav-user .sub-nav").toggleClass("show");
});

// CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger  = $(".category");
    var $trigger2 = $(".nav-user");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".sub-menu").removeClass("visible");
        $(".cat-action").removeClass("active");
    }
    if ($trigger2 !== event.target && !$trigger2.has(event.target).length) {
        $(".nav-user .sub-nav").removeClass("show");
    }
});

// SCROLL TO ANCHOR PRODUCT
$('a[href^="#"]').not(".woocommerce-cart-pdf-email-button").on('click',function (e) {
    e.preventDefault();

    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top-140
    }, 1200, 'swing', function () {
        //window.location.hash = target;
    });
});

if (window.location.hash) {
    var hash = window.location.hash;

    if ($(hash).length) {
        $('html, body').animate({
            scrollTop: $(hash).offset().top-140
        }, 900, 'swing');
    }
}

// AUTOSUBMIT USER Price
$('#acf-field_64abe82a5e954').click(function(e) {
  $("body").toggleClass("overflow");
  $(".spinner").toggleClass("show");
  $("#acf-form-price-user").submit();
});

// AUTOSUBMIT USER Order
$('#acf-field_64774b5292c8b').click(function(e) {
  //e.preventDefault();

  removeLink = window.location.pathname + "?action=empty_cart";

  if(window.location.href.indexOf("/nl") > -1) {
    messageDevis    = "U leegt uw winkelmandje en schakelt over naar offertemodus.";
    messageCommande = "Je leegt je winkelmandje en gaat naar de order modus.";
  }
  else{
    messageDevis    = "Vous allez vider votre panier et passer en mode devis.";
    messageCommande = "Vous allez vider votre panier et passer en mode Commande.";
  }

  if($(this).is(':checked')) {
    if(confirm(messageCommande)){
      $.ajax({

        url: removeLink,
        data: {actionwoo: 'empty_cart'},
        type: 'post',
        //success: function(output) {
                      //alert('Votre demande de devis a bien été vidée !');
                          //}
      });

      $("body").toggleClass("overflow");
      $(".spinner").toggleClass("show");
      $("#acf-form-price-user").submit();
    }
    else{
      $(this).prop( "checked", false );
      $("body").toggleClass("overflow");
      $(".spinner").toggleClass("show");
      $("#acf-form-price-user").submit();
    }
  }
  else{
    if(confirm(messageDevis)){
      $.ajax({

        url: removeLink,
        data: {actionwoo: 'empty_cart'},
        type: 'post',
        //success: function(output) {
                      //alert('Votre demande de devis a bien été vidée !');
                          //}
      });

      $("body").toggleClass("overflow");
      $(".spinner").toggleClass("show");
      $("#acf-form-price-user").submit();
    }
    else{
      $(this).prop( "checked", true );
      $("body").toggleClass("overflow");
      $(".spinner").toggleClass("show");
      $("#acf-form-price-user").submit();
    }
  }

});

// SLIDER SLICK
$(document).ready(function(){

  // Slideshow homepage
  $('.slideshow .slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });

  // Slider catégories
  $('.category-catalogue').slick({
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      // centerMode: true,
      // centerPadding: '60px',

      responsive: [

      {
          breakpoint: 1280,
          settings: {
          infinite: true,
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          }
      },

      {
          breakpoint: 760,
          settings: {
          dots: false,
          arrows: true,
          infinite: true,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          }
      }
      ]
  });

  // Slider Product grid hompe
  $('.products-home-top .products').slick({
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      // centerMode: true,
      // centerPadding: '60px',

      responsive: [

      {
          breakpoint: 1280,
          settings: {
          infinite: true,
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          }
      },

      {
          breakpoint: 760,
          settings: {
          dots: false,
          arrows: true,
          infinite: true,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          }
      }
      ]
  });

  // testimonials
  $('.content-testimonials > .content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'ease',
    fade: true,
    speed: 1000,
  });

});
